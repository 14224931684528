import { FC, PropsWithChildren } from 'react';

import styled from 'styled-components';

import { Box } from '@olist/united';

import { StagingAlert } from '../StagingAlert';

type Props = PropsWithChildren<{ className?: string }>;

const Component: FC<Props> = ({ children, className }) => {
  return (
    <Box className={className}>
      {children}
      <StagingAlert />
    </Box>
  );
};

export const Container: FC<Props> = styled(Component)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;
