import NextLink, { LinkProps } from 'next/link';

import { Text } from '@olist/united';

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

interface NextLinkProps extends Omit<AnchorProps, keyof LinkProps>, LinkProps {
  anchor?: false;
}

interface AnchorElementProps extends AnchorProps {
  href: string;
  anchor?: true;
}

type Props<E = false> = E extends false ? NextLinkProps : AnchorElementProps;

export const Link = <E extends boolean>({ anchor = false, children, ...props }: Props<E>) => {
  if (anchor) {
    return <Text.Link {...props}>{children}</Text.Link>;
  }

  return (
    <NextLink {...props}>
      <Text.Link>{children}</Text.Link>
    </NextLink>
  );
};
