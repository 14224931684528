import { FC, FormEvent, PropsWithChildren } from 'react';

interface Props {
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const Form: FC<PropsWithChildren<Props>> = ({ children, onSubmit }) => {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(event);
      }}
    >
      {children}
    </form>
  );
};
