import styled from 'styled-components';

const Wrapper = styled.div`
  color: darkred;
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
`;

export const ErrorMessage = () => <Wrapper>E-mail ou senha incorretos. Tente novamente.</Wrapper>;
