import styled from 'styled-components';

import { YEAR } from 'modules/common/constants';

const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 32px 64px;
  width: 100%;
`;

export const Footer = () => {
  return (
    <Wrapper>
      <div>
        <a href="https://olist.com.br" target="_blank" rel="noreferrer">
          olist.com.br
        </a>{' '}
        © {YEAR} Todos os direitos reservados
      </div>
    </Wrapper>
  );
};
