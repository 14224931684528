import { FC, PropsWithChildren } from 'react';

import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid rgb(209, 209, 227);
  border-radius: 16px;
  box-sizing: border-box;
  max-width: 410px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  width: 100%;
`;

export const FormContainer: FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};
