import { useCallback } from 'react';

import { Icon, Tooltip } from '@olist/united';

interface Props {
  visible: boolean;
  onChange: (visible: boolean) => void;
}

export const PasswordVisibilityAdornment = ({ onChange, visible }: Props) => {
  const onToggle = useCallback(() => onChange(!visible), [visible, onChange]);

  return (
    <Tooltip content={visible ? 'Ocultar senha' : 'Exibir senha'} placement="top">
      <button
        type="button"
        style={{
          pointerEvents: 'all',
          cursor: 'pointer',
          background: 'none',
          border: 'none',
        }}
        onClick={onToggle}
      >
        <Icon name={visible ? 'visible-outline' : 'visible-off-outline'} />
      </button>
    </Tooltip>
  );
};
