export enum ROUTES {
  SIGNIN = '/entrar',
  SIGNOUT = '/sair',
  SHOPS_LIST = '/admin',
  PASSWORD_RESET = '/esqueci-minha-senha',
  PASSWORD_UPDATE = '/atualizar-minha-senha',
  GO_TO_SHOP_PREFIX = '/api/shop/',
  UNAUTHORIZED = '/nao-autorizado',
  MFA_AUTH_PREFIX = '/api/mfa/',
  MFA_ACTIVATION = '/mfa/habilitar',
  MFA_AUTHENTICATION = '/mfa/entrar',
}
